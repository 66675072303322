import React from 'react'
import './Hero.css'
import Header from '../Header/Header'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import Heart from '../../assets/heart.png'
import Calories from '../../assets/calories.png'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'


const Hero = () => {
  const transition={type:'spring',duration:3}
  const mobile=window.innerWidth<=768 ? true:false;
  return (
    <div className="hero" id='home'>
      <div className="blur hero-blur"></div>
        <div className="left-h"><Header/>
        <div className='the-best-ad'>
          <motion.div
          initial={{left:mobile?'170px':'238px'}}
          whileInView={{left:'8px'}}
          transition={{...transition,type:'tween'}}

          ></motion.div>
            <span>Best fitness club in the town</span>
          
        </div>
        {/* hero heading */}
        <div className='hero-text'>
          <div>
            <span className='stroke-text'>Shape</span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal Body</span>
          </div>
          <div>
            <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Recusandae, blanditiis.</span>
          </div>
        </div>
        {/* figures */}
        <div className='figures'>
          <div>
            <span>
              <NumberCounter end={140} start={100} delay='4' prefix='+'/>
            </span>
            <span>Expert Coaches</span>
          </div>
          <div>
          <span>
          <NumberCounter end={978} start={900} delay='4' prefix='+'/>
          </span>
            <span>Members Joined</span>
          </div>
          <div>
          <span>
          <NumberCounter end={50} start={30} delay='4' prefix='+'/>
          </span>
            <span>Fitness Programs</span>
          </div>
        </div>
        {/* hero buttons */}
        <div className='hero-button'>
          <button className='btn'>Get Started</button>
          <button className='btn'>Learn More</button>
        </div>
        </div>
        <div className="right-h">
          <button className='btn'>Join Now</button>
          <motion.div 
          initial={{right:'-1rem'}}
          transition={transition}
          whileInView={{right:'4rem'}}
          className='heart-rate'
          >
         
            <img src={Heart} alt="" />
            <span>Heart Rate</span>
            <span>116 bpm</span>
            </motion.div>
         
          {/* hero image */}
          <img src={hero_image} alt="" className="hero-image"/>
          <motion.img
          initial={{right:'11rem'}}
          whileInView={{right:'20rem'}}
          transition={transition}
          src={hero_image_back} alt="" className="hero-image-back"/>
          {/* calories */}
          <motion.div 
          initial={{right:'37rem'}}
          whileInView={{right:'28rem'}}
          transition={transition}
          className='calories'>
            <img src={Calories} alt=""/>
            <div>
            <span>CaloriesBurned</span>
            <span>220kcal</span>
            </div>
          </motion.div>
        </div>
      
    </div>
  )
}

export default Hero
